.mtf{

}
.mf-container{
    padding: 5px 10px;
}
.mtf-wrap{
    display: flex;
    flex-flow: row wrap;
}
.mtf-wrap>div{
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.mtf-item{
    display: inline-block;
    /* padding-right: 10px; */
    vertical-align: text-top;
    margin-top: 7px;
    margin-bottom: 7px;
}

.mtf-item .form-group{
    margin-bottom: 0px;
}

.mtf-item .form-control:focus {
    color: #768192;
    background-color: #fff;
    border-color: #20a8d8;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25);
}
/* Overide css */
.mtf-wrap .form-group{
    margin-bottom: 0px;
}
.mtf-wrap .Select-control{
    border: "0px";
}

/* style2 */
.mtf-style2 {
    border: 0px;
    /* border-bottom: 1px solid #dcdcdc; */
}

.mtf-style2 .input-group-prepend{
    display: none;
}

.mtf-style2 .mtf-search input{
    background-color: #eeeeee;
    border: 0px;
}

.mtf-style2 .Select-control{
    border: 0px;
}

/* style onlysearch */
.mtf-style-onlysearch .mtf-item{
    padding: 0px;
}
.mtf-style-onlysearch .form-group{
    margin: 0px;
}

/* clear*/
.mtf-btn-clear{
    font-size: 10px;
    position: absolute;
    right: 2px;
    bottom: 2px;
    color: #d8216a;
    cursor: pointer;
    display: block;
    float: right;
}