.mct-col1{
  position: relative!important;
  min-height: 30px!important;
}
.mct-readonly{
  padding: 2px;
  white-space: pre-line;
}
.mct-text>input, .mct-textarea>textarea, .mct-select>select {
  border: 0px;
  border-radius: 0px;
  padding: 2px;
  min-height: 100%;
}
.mct-checkbox{
  display: flex;
  justify-content: center;
  align-items: center;
}
.mct-button{
  padding: 2px;
}
.mct-button button{
  padding: 2px;
  width: 100%;
  height: 100%;
}
.mct-date>input {
  border: 0px;
  border-radius: 0px;
  padding: 2px;
  min-height: 100%;
}
.mct-delete{
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  width: 100%;
}
.mct-delete button{
  color: #f86c6b;
  background-color: transparent;
  width: 100%;
  padding: 0px;
  border-radius: 0;
}
.mct-delete button:hover{
  color: #fff;
  background-color: #f86c6b;
}

.mct-status{
  align-items: center;
  position: absolute;
  top: 0px;
  right: 0px;
  color: #ff0000;
  cursor: pointer;
  justify-content: center;
  display: flex;
}

.mct-status>.mct-status-error{
  align-items: center;
  color: #ff0000;
  cursor: pointer;
  width: 20px;
  height: 20px;
  justify-content: center;
  display: flex;
  position: relative;
}

.mct-status>.mct-status-saving{
  align-items: center;
  color: #333;
  cursor: pointer;
  width: 20px;
  height: 20px;
  justify-content: center;
  display: flex;
}
.mct-status>.mct-status-edit{
  align-items: center;
  color: #20a7d8;
  cursor: pointer;
  width: 20px;
  height: 20px;
  justify-content: center;
  display: flex;
}

.dFaiCjcC{
  display: flex;
  justify-content: center;
  align-items: center;
}

.mct-expand{
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
}
.mct-expand button{
  padding: 0px;
  border: 0px;
  width: 100%;
  background-color: rgb(242, 242, 242);
  font-size: 20px;
}
.mct-expand .fa-chevron-circle-down{
  color: #44cfae;
}

.mct-expand .fa-chevron-circle-up{
  color: #f44336;
}

.mct-readonly-array div{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.mct-readonly-array span {
  margin: 2px;
  padding: 2px;
  border: 1px solid rgba(0, 126, 255, 0.08);
  border-radius: 3px;
  background-color: rgba(0, 126, 255, 0.08);
  color: #007eff;
  white-space: normal;
}
.mct-textareafull{
  width: 100%;
  height: 88px;
  min-height: 100%;
  border: 0px solid;
  display: flex;
  align-items: center;
}

.mct-addable{
  position: absolute;
  right: 0;
  bottom: 0;
  height: 30px;
  width: 30px;
  background-color: 'transparent';
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}

.mct-addable button{
  color: green;
  border: 0;
  font-weight: 400;
  text-decoration: none;
}

.mct-addable .btn:hover{
  color: green;
  border: 0;
  font-weight: 400;
  text-decoration: none;
}
.mct-array-editable{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.sp-btn-blue {
  margin: 2px;
  padding: 2px;
  border: 1px solid rgba(0, 126, 255, 0.08);
  border-radius: 3px;
  background-color: rgba(0, 126, 255, 0.08);
  color: #007eff;
  white-space: normal;
}
.mct-array-editable .btn-link{
  color: green;
  border: 0;
  font-weight: 400;
  text-decoration: none;
}

.mct-array-editable .btn-link:hover{
  color: green;
  border: 0;
  font-weight: 400;
  text-decoration: none;
}

.mct-array-editable .btn-delete{
  padding: 0px 4px;
  width: 22px;
  height: 22px;
  color: #f86c6b;
}
.mct-array-editable .btn-delete:hover{
  padding: 0px 4px;
  width: 22px;
  height: 22px;
  color: #f86c6b;
}
.mct-files{
  display: flex;
  align-items: center;
  justify-content: center;
}
.mct-files .btn-link{
  font-weight: 400;
  color: #20a8d8;
  text-decoration: none;
  border: 0px;
  background-color: transparent;
  position: relative
}

.mct-files .btn-link:not(:disabled):not(.disabled):active, .mct-files .btn-link:focus{
  font-weight: 400;
  color: #20a8d8;
  text-decoration: none;
  border: none;
  box-shadow: none;
  background-color: transparent;
}

.mct-files .badge-files{
  top: 0;
  right: 0;
  height: 16px;
  background-color: #ff5722b8;
  color: #fff;
  border-radius: 10px;
  font-size: 11px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  position: absolute;
}